<template>
    <div class="flex align-items-center justify-content-between flex-wrap">
        <h4 class="font-bold mb-5 text-gray-800 hover:text-primary">Facultad en revisión</h4>
        <div class="my-1 hidden">
        </div>
    </div>
    <div class="font-normal text-base mb-3">
        <p>Los planes mostrados en el siguiente listado corresponden a los que se encuentran en revisión, la notificación se realiza por facultad y los cargos en esa unidad de Vice-Decano y Decano de cualquier de las sedes.
        <a href="javascript:;" class="text-primary"></a> </p>
    </div>
    <div class="mb-5">
        <DataTable :value="facultadList" v-model:expandedRows="planList" dataKey="fac_nombre" responsiveLayout="scroll" :loading="loading">
            <template #loading>
                 <p class="font-italic text-center">Espere, estamos buscando los planes en revisión.</p>
            </template>
            <Column :expander="true" headerStyle="width: 2rem" />
            <Column header="Facultad">
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                        <div class="flex flex-column" >
                            <span class="text-primary font-bold">{{slotProps.data.fac_nombre}} ({{slotProps.data.planDidacticoList.length}})</span>
                            <span class="text-muted text-xs">{{slotProps.data.notifica_a.replaceAll(";"," 📧 ")}}</span>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="sendNotification_onClick(slotProps.data)" v-tooltip.top="'Notificar'" > 
                            <i class="pi pi-send"></i>
                        </a>
                    </div>
                </template>
            </Column>
            <template #expansion="slotProps">
                <div class="orders-subtable">
                    <h5 class="text-base text-muted font-bold "> Plan didáctico de {{slotProps.data.fac_nombre}} ({{slotProps.data.planDidacticoList.length}})</h5>
                    <DataTable :value="slotProps.data.planDidacticoList" responsiveLayout="scroll">
                        <Column field="" header="#" headerStyle="width: 50px" headerClass="status">
                            <template #body="{index}">
                                <span class="font-semibold text-gray-800">{{ index + 1}}</span>
                            </template>
                        </Column>
                        <Column  field="plm_mat_nombre" header="Asignatura"  headerClass="status" >
                            <template #body="{data}">
                                <div class="flex align-items-center">
                                    <div class="ml-3">
                                        <span class="font-semibold text-gray-800">{{data.plm_mat_nombre}}</span>
                                        <div class="mt-1 font-semibold text-gray-600 text-sm">{{data.pld_nomcil}}  - {{data.pla_nombre.toUpperCase()}} </div>
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column  header="Carrera"  headerClass="status">
                            <template #body="{data}">
                                <div class="flex align-items-center">
                                    <div class="">
                                        <span class="font-semibold text-gray-800">{{data.car_nombre}}</span>
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
        <div class="font-normal text-base mb-3">
        <p class="text-danger">* Los datos mostrados se actualizan en el proceso normal de administración, edición y aprobación de plan por los usuarios de la comisión y usuarios de revisión/aprobación.</p>
    </div>
</template>




<script>

export default {
    components:{
    },
    props:{
        Service:null
    },
    emits:[],
    data(){

        return  {
            facultadList : [],
            planList: [] ,
            loading : false,
        }
    },
    created(){
    },
    async mounted(){
        this.loading = true
        this.facultadList =  await  this.Service.getPlanByFacultad(0);
        this.loading = false
    },
    methods:{

        async sendNotification_onClick(data){
            var porFacultad =  true;
            var notificar =  true;

            this.$confirm.require({
                message: `¿Está seguro de notificar el resumen por facultad: ${data.fac_nombre}?`,
                header: 'Notificar',
                icon: 'pi pi-info-circle',
                acceptLabel: "Si, enviar.",
                acceptClass: 'p-button-info',
                accept: async () => {
                    try{
                        await  this.Service.getPlanByFacultad(data.fac_codigo, porFacultad, notificar);
                        this.$toast.add({severity:'info', summary:'¡Notificado!', detail:"Se ha creado la notificación y será enviada en breve", life: 3000});
                    }catch(ex){
                        var msg =  ex;
                        if(msg.response)
                            msg= msg.response.data;

                        this.$toast.add({severity:'error', summary:'¡Ocurrió un error!', detail:msg, life: 3000});
                    }
                },
            });
        }
    },
    computed:{

    }
}
</script>

<style>
    .p-datatable-thead th.status{
        color: #806126 !important;
        background-color: rgba(255, 195, 54, 0.1) !important; 
    }
</style>