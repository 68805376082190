<template>
    <div class="grid">
		<div class="col-12 md:col-12 lg:col-10 xl:col-10 xl:col-offset-1  lg:col-offset-1">
            <div class="p-card">
                <div class="p-card-body">
                    <div class="grid">
                        <div class="col-12 lg:col-2">
                            <ul class="navi navi-link-rounded navi-accent navi-primary navi-hover mb-3 md:mb-0">
                                <li class="navi-item " v-for="(tab, index) in tabValues" :key="index">
                                    <a class="navi-link" :class="{'active': d_activeIndex == index}" @click="onTabClick($event, index)"> 
                                        <span class="navi-text text-lg font-bold mb-0">{{tab}}</span>    
                                    </a>
                                </li>
                            </ul>
                           
                        </div>
                        <!-- ESQUEMA -->
                        <div class="col-12 lg:col-10" v-if="d_activeIndex == 0">
                            <PlanFacultadPanel :Service="planDidactivoService"/>
                        </div>
                        <div class="col-12 lg:col-10" v-if="d_activeIndex == 1"  data-tab="tab-empleado">
                            <PlanEmpleadoPanel :Service="planDidactivoService"/>
                        </div>
                        <!--Begin: Criterio -->
                        <div class="col-12 lg:col-10" v-if="d_activeIndex == 2"  data-tab="tab-criterio">
                           
                        </div>
                        <!--End: Criterio -->

                        <!--Begin: Escala -->
                        <div class="col-12 lg:col-10" v-if="d_activeIndex == 3"  data-tab="tab-escala">
                            
                        </div>
                        <!--End: Escala -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import PlanFacultadPanel from "@/components/LaborDocente/Utilidades/PlanFacultadPanel"
import PlanEmpleadoPanel from "@/components/LaborDocente/Utilidades/PlanEmpleadoPanel"
import PlanDidacticoService from '@/service/planDidacticoService'
export default {
    planDidactivoService : null,
    components:{
        PlanFacultadPanel,
        PlanEmpleadoPanel
    },

    data(){
        return {
            d_activeIndex  : -1,
            tabValues: ["Facultad en Revisión","Empleados en Elaboración"],
        }
        
    },
    created(){
       this.planDidactivoService =  new PlanDidacticoService(this.axios); 
       this.d_activeIndex = 0
    },

    async mounted(){
      
        
    },

    methods:{
        async onTabClick(event, i) {
            if (i !== this.d_activeIndex) {
                this.d_activeIndex = i;
            }
        },
    },

    computed:{
    }
}
</script>

<style scoped>
    /*Navi*/
    
</style>