<template>
    <div class="flex align-items-center justify-content-between flex-wrap">
        <h4 class="font-bold mb-5 text-gray-800 hover:text-primary">Empleado en Elaboración</h4>
        <div class="my-1 hidden">
        </div>
    </div>
    <div class="font-normal text-base mb-3">
        <p>Los planes mostrados en el siguiente listado corresponden a los que se encuentran en elaboración, la notificación se realiza por empleado en estado activo-.
        <a href="javascript:;" class="text-primary"></a> </p>
    </div>
    <div class="mb-5">
        <DataTable :value="empleadoList" v-model:expandedRows="planList" dataKey="emp_codigo" stripedRows responsiveLayout="scroll" :loading="loading"  :rows="25" 
            :paginator="true" paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport  RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} "
            :rowsPerPageOptions="[10, 25,50,100,]"
        >
            <template #loading>
                 <p class="font-italic text-center">Espere, estamos buscando los planes en elaboración.</p>
            </template>
            <Column :expander="true" headerStyle="width: 2rem"/>
            <Column>
                <template #header>
                    <div class="flex justify-content-between w-full">
                        <div class="flex flex-column" >
                            <span class="text-primary font-bold">Empleado</span>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="sendNotification_onClick(null)" v-tooltip.top="'Notificar'" > 
                            <i class="pi pi-send"></i> Notificar todos
                        </a>
                    </div>
                </template>
                <template #body="slotProps">
                    <div class="flex justify-content-between">
                        <div class="flex flex-column" >
                            <span class="text-primary font-bold">{{slotProps.data.empleado.emp_nombres_apellidos}} ({{slotProps.data.planDidacticoList.length}})</span>
                            <span class="text-muted text-xs">📧 {{slotProps.data.empleado.emp_correo}} 👨‍💼 {{slotProps.data.empleado.emp_puesto}} 💻 {{slotProps.data.empleado.emp_unidad}}</span>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="sendNotification_onClick(slotProps.data)" v-tooltip.top="'Notificar'" > 
                            <i class="pi pi-send"></i>
                        </a>
                    </div>
                </template>
            </Column>
            <template #expansion="slotProps">
                <div class="orders-subtable">
                    <h5 class="text-base text-muted font-bold "> Plan didáctico de {{slotProps.data.fac_nombre}} ({{slotProps.data.planDidacticoList.length}})</h5>
                    <DataTable :value="slotProps.data.planDidacticoList" responsiveLayout="scroll">
                        <Column field="" header="#" headerStyle="width: 50px" headerClass="status">
                            <template #body="{index}">
                                <span class="font-semibold text-gray-800">{{ index + 1}}</span>
                            </template>
                        </Column>
                        <Column  field="plm_mat_nombre" header="Asignatura"  headerClass="status" >
                            <template #body="{data}">
                                <div class="flex align-items-center">
                                    <div class="ml-3">
                                        <span class="font-semibold text-gray-800">{{data.plm_mat_nombre}}</span>
                                        <div class="mt-1 font-semibold text-gray-600 text-sm">{{data.pld_nomcil}}  - {{data.pla_nombre.toUpperCase()}} </div>
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column  header="Carrera"  headerClass="status">
                            <template #body="{data}">
                                <div class="flex align-items-center">
                                    <div class="">
                                        <span class="font-semibold text-gray-800">{{data.car_nombre}}</span>
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
        <div class="font-normal text-base mb-3">
        <p class="text-danger">* Los datos mostrados se actualizan en el proceso normal de administración, edición y aprobación de plan por los usuarios de la comisión y usuarios de revisión/aprobación.</p>
    </div>
</template>




<script>

export default {
    components:{
    },
    props:{
        Service:null
    },
    emits:[],
    data(){

        return  {
            empleadoList : [],
            planList: [] ,
            loading : false,
        }
    },
    created(){
    },
    async mounted(){
        this.loading = true
        var emp_codigo = 0
        this.empleadoList =  await  this.Service.getPlanByEmpleado(emp_codigo);
        this.loading = false
    },
    methods:{

        async sendNotification_onClick(data = null){
            var porEmpleado =  true ;
            var notificar =  true;

            var caption =  data != null ?  data.emp_nombres_apellidos : " A TODOS"
            var codigo  =  data != null ? data.emp_codigo : 0 


            this.$confirm.require({
                message: `¿Está seguro de notificar el resumen por empleado: ${caption}?`,
                header: 'Notificar',
                icon: 'pi pi-info-circle',
                acceptLabel: "Si, enviar.",
                acceptClass: 'p-button-info',
                accept: async () => {
                    try{
                        await  this.Service.getPlanByEmpleado(codigo, porEmpleado, notificar);
                        this.$toast.add({severity:'info', summary:'¡Notificado!', detail:"Se ha creado la notificación y será enviada en breve", life: 3000});
                    }catch(ex){
                        var msg =  ex;
                        if(msg.response)
                            msg= msg.response.data;

                        this.$toast.add({severity:'error', summary:'¡Ocurrió un error!', detail:msg, life: 3000});
                    }
                },
            });
        }
    },
    computed:{

    }
}
</script>

<style>
    .p-datatable-thead th.status{
        color: #806126 !important;
        background-color: rgba(255, 195, 54, 0.1) !important; 
    }
</style>


<style scoped lang="scss">
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>